import React from "react"
import { graphql } from "gatsby"
import type { FileEdge, Query } from "types/graphql-types"

import Layout from "@layouts/Layout"
import { Columns } from "@components/Columns"
import { Image } from "@components/Image"
import SEO from "@components/Seo"

const FeedPage = ({ data }: { data: Query }) => {
    const { edges } = data.allFile
    const posts = edges.filter((edge): edge is FileEdge => Boolean(edge.node))

    return (
        <Layout hideNavigation={posts.length === 0}>
            <SEO title="Image Feed" />
            <Columns wide>
                {posts.map(({ node: img }) => (
                    <Image key={img.id} file={img} />
                ))}
            </Columns>
        </Layout>
    )
}

export const pageQuery = graphql`
    query ImageFeedQuery {
        allFile(filter: { url: { ne: null } }, sort: { fields: modifiedTime, order: DESC }) {
            edges {
                node {
                    id
                    childImageSharp {
                        fluid(maxWidth: 800) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                    fields {
                        slug
                    }
                }
            }
        }
    }
`

export default FeedPage
